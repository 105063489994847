{
  "company.name": "MVE",
  "company.name&status": "MVE SAM",
  "company.address": "31, Avenue Princesse Grace - 98000 Monaco",
  "company.phone": "+377 97 97 51 03",

  "header.open-main-menu": "Ouvrir le menu",
  "header.close-main-menu": "Fermer le menu",
  "header.nav.home": "Accueil",
  "header.nav.services": "Services",
  "header.nav.about": "À propos de nous",
  "header.nav.contact": "En savoir plus",
  "header.nav.get-started": "Commencer",
  "header.calls-to-action.send-mail": "Envoyez-nous un mail",
  "header.calls-to-action.call-sales": "Appelez les ventes",
  "header.company.certifications": "Certifications",
  "header.company.certifications.description": "En savoir plus sur nos valeurs d'entreprise et nos certifications",
  "header.company.careers": "Carrières",
  "header.company.careers.description": "Vous recherchez votre prochaine opportunité de carrière ? Voir toutes nos positions ouvertes",
  "header.company.support": "Contact",
  "header.company.support.description": "Contactez notre équipe pour toute question ou demande",
  "header.company.fonds-bleu": "Fond Bleu",
  "header.company.fonds-bleu.description": "Le Fond Bleu s’appuie sur la plateforme Extended Monaco pour l’Entreprise, outil d’accompagnement de la transition numérique des entreprises monégasques.",
  "header.new": "Nouveau",

  "hero.hiring": "Nous recrutons",
  "hero.open-positions": "Voir les postes ouverts",
  "hero.title": "MVE garantit sécurité, analyse, formation, innovation",
  "hero.subtitle": "MVE est spécialisé depuis plus de dix ans dans la cybersécurité, la domotique, la vidéo-surveillance et la gestion de projets sensibles. Nous disposons de toutes les ressources, compétences et certifications indispensables pour soutenir nos clients et répondre à leurs besoins.",
  "hero.get-started": "Commencer",
  "hero.learn-more": "Téléchargez notre présentation",
  "hero.pdf-name": "MVE_SAM_DESK_FR.pdf",

  "banner.title": "Nos employés hautement qualifiés et bien formés travaillent sur la cybersécurité, le réseau, la domotique... À l'ère de l'information, les installations classiques ne suffisent plus pour assurer les performances et la confidentialité dont nos clients ont besoin.",
  "banner.author": "Cristiano Toso",
  "banner.roles": "PDG & Fondateur de MVE",

  "services": "Nos services",
  "services.title": "Tout le savoir-faire de MVE",
  "services.subtitle": "MVE, votre partenaire en cybersécurité et sécurité.",
  "services.cyber-security": "Cybersécurité",
  "services.cyber-security.description": "Protection des systèmes informatiques et des réseaux contre le vol, les dommages, ou l'accès non autorisé.",
  "services.security": "Sécurité",
  "services.security.description": "Protection des actifs physiques et numériques grâce à la surveillance, le contrôle d'accès, et l'atténuation des risques.",
  "services.smart-home": "Maison intelligente",
  "services.smart-home.description": "Conception et installation de systèmes domotiques, tels que les réseaux de données, les systèmes de sécurité, et les équipements audiovisuels.",
  "services.cloud": "Intégrateur Cloud",
  "services.cloud.description": "Solutions de cloud computing sécurisées et personnalisées pour le stockage, la gestion, et le traitement des données.",
  "services.blockchain": "Blockchain",
  "services.blockchain.description": "Sécurisation et décentralisation des transactions numériques à travers des blocs liés cryptographiquement.",
  "services.devops": "DevOps",
  "services.devops.description": "Optimisation des processus de développement et d'opération pour une intégration et une livraison continues, garantissant rapidité et fiabilité.",

  "contact.title": "Nous contacter",
  "contact.subtitle": "Remplissez le formulaire pour toutes vos demandes, notre équipe vous contactera dès que possible.",
  "contact.form.firstname": "Prénom",
  "contact.form.lastname": "Nom",
  "contact.form.company": "Entreprise",
  "contact.form.email": "Email",
  "contact.form.phone": "Numéro de téléphone",
  "contact.form.message": "Message",
  "contact.form.agree-to-policies": "Accepter les politiques",
  "contact.form.agree-to-policies-validation-1": "En sélectionnant ceci, vous acceptez notre",
  "contact.form.agree-to-policies-validation-2": "confidentialité",
  "contact.form.agree-to-policies-validation-3": "politique",
  "contact.form.submit": "Parlons-en",

  "about-us.title": "Renforçons notre sécurité en ligne.",
  "about-us.description": "Nous sommes une entreprise de cybersécurité axée sur l'aide aux entreprises pour protéger leurs données et maintenir leur sécurité en ligne. Nous croyons en la création d'un environnement numérique plus sûr grâce à des solutions et services innovants.",
  "about-us.mission": "Notre mission",
  "about-us.mission.description-1": "Notre mission est de fournir des services de cybersécurité de haut niveau qui protègent l'information et les systèmes de nos clients contre toutes les menaces.",
  "about-us.mission.description-2": "Nous visons à construire la confiance et la fiabilité à travers notre travail, rendant le monde numérique plus sûr pour tout le monde.",
  "about-us.stats.first.value": "24/7",
  "about-us.stats.first.label": "Support en ligne",
  "about-us.stats.second.value": "50+",
  "about-us.stats.second.label": "Ingénieurs pour vous",
  "about-us.stats.third.value": "10 ans+",
  "about-us.stats.third.label": "Expérience dans le domaine",
  "about-us.team": "Notre équipe",
  "about-us.team.description": "Nous sommes un groupe dynamique de personnes passionnées par ce que nous faisons et dédiées à offrir les meilleurs résultats à nos clients.",
  "about-us.values": "Nos valeurs",
  "about-us.values.description": "Nos valeurs reflètent notre dévouement à offrir des solutions de cybersécurité de haute qualité, à maintenir une approche axée sur le client, et à innover continuellement dans notre domaine. Nous croyons en la puissance de la technologie et nous nous engageons à l'utiliser pour rendre le monde plus sûr.",
  "about-us.values.first.name": "Pousser à déployer.",
  "about-us.values.first.description": "MVE avec le soutien de Lutron et Crestron construit des systèmes audio/vidéo/domotiques professionnels faciles à utiliser.",
  "about-us.values.second.name": "Certificats cyber.",
  "about-us.values.second.description": "MVE déploie des logiciels de pointe et des auditeurs certifiés pour assurer un réseau protégé.",
  "about-us.values.third.name": "Utilisation simple.",
  "about-us.values.third.description": "Nous garantissons un environnement stable et convivial.",
  "about-us.values.fourth.name": "Sécurité avancée.",
  "about-us.values.fourth.description": "Système de gestion vidéo, ANPR, reconnaissance faciale, outils biométriques à votre soutien.",
  "about-us.values.fifth.name": "Environnement puissant.",
  "about-us.values.fifth.description": "Donner le pouvoir aux personnes, aux entreprises et aux sociétés avec une technologie vidéo basée sur les données.",
  "about-us.values.sixth.name": "Cloud.",
  "about-us.values.sixth.description": "MVE offre des services robustes en cloud qui fournissent des solutions de stockage sécurisées, évolutives et fiables pour les besoins en données et en technologie vidéo. Partenaire Monaco Cloud.",

  "footer.quote": "Renforçons notre sécurité en ligne.",
  "footer.all-rights-reserved": "Tous droits réservés.",
  "footer.services.title": "Services",
  "footer.company.title": "Entreprise",
  "footer.company.certifications": "Certifications",
  "footer.company.careers": "Carrières",
  "footer.company.support": "Contact",
  "footer.company.about-us": "À propos de nous",
  "footer.company.fond-bleu": "Fond Bleu",
  "footer.legal.title": "Légal",
  "footer.legal.legal-info": "Informations légales",
  "footer.legal.terms-of-use": "Conditions d'utilisation",
  "footer.legal.cookies-policy": "Politique de cookies",

  "careers.title": "Travaillons ensemble.",
  "careers.subtitle": "Vous voulez rejoindre l'équipe de rêve ? Remplissez ce formulaire et nous vous répondrons dès que possible",
  "careers.form.firstname": "Prénom",
  "careers.form.lastname": "Nom",
  "careers.form.email": "Email",
  "careers.form.company": "Entreprise",
  "careers.form.phone": "Numéro de téléphone",
  "careers.form.optional": "Facultatif",
  "careers.form.message": "Message",
  "careers.form.max-length": "Max 500 caractères",
  "careers.form.submit": "Envoyer le message",

  "error.404": "404",
  "error.title": "Page non trouvée",
  "error.description": "Désolé, nous n'avons pas pu trouver la page que vous cherchez.",
  "error.back-home": "Retour à l'accueil",

  "certifications.title": "Certifications",
  "certifications.subtitle": "Tout le savoir-faire de MVE par des ingénieurs qualifiés",
  "certifactions.certificate-1": "Fournisseur d'audit (PASSI)",
  "certifactions.certificate-2": "Partenaire Milestone",
  "certifactions.certificate-3": "Partenaire Chainalysis",
  "certifactions.certificate-4": "Partenaire Monaco Cloud",
  "certifactions.certificate-5": "Certification Lutron",
  "certifactions.certificate-6": "Certification Crestron",

  "cybersecurity.title": "La cybersécurité est une question de sécurité collective.",
  "cybersecurity.subtitle": "Une équipe d'auditeurs et d'experts en cybersécurité pour vous accompagner dans les domaines de la Prévention - Protection - Réaction et Formation.",
  "cybersecurity.firstdate.date": "31 juillet 2020",
  "cybersecurity.firstdate.datetime": "2020-08",
  "cybersecurity.firstdate.title": "Certification PASSI",
  "cybersecurity.seconddate.date": "15 novembre 2020",
  "cybersecurity.seconddate.datetime": "2020-11",
  "cybersecurity.seconddate.title": "Premier contrat Cyber",
  "cybersecurity.thirddate.date": "8 janvier 2021",
  "cybersecurity.thirddate.datetime": "2021-01",
  "cybersecurity.thirddate.title": "Première récompense pour une mission de récupération de données",
  "cybersecurity.fourthdate.date": "10 février 2022",
  "cybersecurity.fourthdate.datetime": "2022-02",
  "cybersecurity.fourthdate.title": "Recrutement de développeurs IT juniors et lead dans notre équipe",
  "cybersecurity.fifthdate.date": "26 avril 2023",
  "cybersecurity.fifthdate.datetime": "2023-04",
  "cybersecurity.fifthdate.title": "Nouveaux défis internationaux passionnants",
  "cybersecurity.banner.title": "Nos clients nous adorent",
  "cybersecurity.banner.description": "Nous sommes un partenaire de confiance pour les entreprises du monde entier, fournissant des solutions de cybersécurité de pointe.",
  "cybersecurity.features.title": "Équipe d'experts",
  "cybersecurity.features.description": "Notre équipe est composée de professionnels dédiés et expérimentés en cybersécurité, avec une profonde expertise dans divers aspects de la cybersécurité.",
  "cybersecurity.careers.title": "Nous recherchons toujours des personnes extraordinaires pour nous rejoindre",
  "cybersecurity.careers.description": "Rejoignez notre équipe hautement qualifiée et faites une vraie différence dans la protection des entreprises contre les menaces de cybersécurité.",
  "cybersecurity.careers.link": "Vous voulez nous rejoindre ? Postulez ici",

  "cookies-policy": "Politique des cookies",
  "cookies-policy.title": "Traitement de vos données personnelles",
  "cookies-policy.first-text": "MVE s'engage à ce que le traitement des données personnelles effectué sur mve.mc soit conforme :",
  "cookies-policy.first-text-1": "À la loi 1.165 du 23/12/1993",
  "cookies-policy.first-text-2": "sur la protection des informations personnelles.",
  "cookies-policy.first-text-3": "Au Règlement général sur la protection des données (RGPD) - Directive 2002/58/EC",
  "cookies-policy.first-text-4": "\"privacy and electronic communications\" du Parlement européen et du Conseil du 12 juillet 2002 concernant le traitement des données personnelles et la protection de la vie privée dans le secteur des communications électroniques.",
  "cookies-policy.second-text": "Vous pouvez vous opposer au traitement de vos données personnelles en refusant les cookies.",
  "cookies-policy.first-subtitle": "Données personnelles collectées :",
  "cookies-policy.third-text-1": "Limitées au strict nécessaire",
  "cookies-policy.third-text-2": "Elles sont conservées pendant 1 an",
  "cookies-policy.third-text-3": "Elles sont traitées selon des protocoles sécurisés",

  "cookies-policy.second-subtitle": "À propos des cookies :",
  "cookies-policy.third-subtitle": "Deux types de cookies sont utilisés par mve.mc :",
  "cookies-policy.fifth-text-1": "Les cookies pour mémoriser vos préférences",
  "cookies-policy.fifth-text-2": "Les cookies pour mesurer l'audience",
  "cookies-policy.more-information": "Pour toute information ou pour exercer vos droits concernant le traitement des données, vous pouvez contacter notre Délégué à la protection des données (DPO)",
  "cookies-policy.contact": "Contact",

  "legal": "Mentions légales",
  "legal.title": "Informations légales",
  "legal.text-1": "Conformément à la loi, nous vous invitons à prendre connaissance des présentes conditions d'utilisation et mentions légales relatives au site web www.mve.mc",
  "legal.text-2": "L'ensemble de ce site relève de la législation française et internationale sur le droit d'auteur et la propriété intellectuelle. Tous les droits de reproduction sont réservés, y compris pour les documents iconographiques et photographiques.",
  "legal.subtitle.first": "Informations légales et dispositif de publication responsable de la publication",
  "legal.text-3": "Le site www.mve.mc est publié par la société MVE, une société à responsabilité limitée avec un capital de 150 000 euros, dont le siège social est situé au 31, Av. Princesse Grace, 98000 Monaco, inscrite au Registre du Commerce et des Sociétés de Monaco sous le numéro RCI 12S05741, numéro de TVA intracommunautaire FR 81 00009663 5.",
  "legal.subtitle.second": "Adresse postale",
  "legal.text-4": "MVE SAM",
  "legal.text-5": "31, Av. Princesse Grace",
  "legal.text-6": "L'Estoril - Bloc A",
  "legal.text-7": "98000 Monaco",
  "legal.subtitle.third": "Contact",
  "legal.text-8": "Téléphone : ",
  "legal.text-9": "Email : ",
  "legal.text-10": "Pour toute question ou réaction concernant le site, nous vous invitons à nous contacter par e-mail ou via le formulaire de contact.",
  "legal.subtitle.fourth": "Propriété intellectuelle",
  "legal.text-11": "Le site web ainsi que tous les éléments qui le composent et notamment : textes, images fixes ou animées, compositions sonores, charte graphique, logiciels et bases de données etc. sont la propriété exclusive de MVE et sont protégés au titre des dispositions du Code de la Propriété Intellectuelle.",
  "legal.text-12": "En conséquence, toute reproduction ou représentation de ce site, en tout ou partie, toute extraction de la base de données par quelque moyen que ce soit, sans l'autorisation expresse de MVE est interdite. Le non-respect de ces dispositions constituerait une contrefaçon sanctionnée selon les dispositions du Code de la Propriété Intellectuelle et du Code Pénal. Les marques, noms commerciaux et logos figurant sur ce site sont déposés. Leur reproduction totale ou partielle, faite à partir des éléments du site, est interdite.",

  "cookies-pop-up.title": "Avis sur les cookies",
  "cookies-pop-up.text": "Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site web.",
  "cookies-pop-up.accept": "Accepter",
  "cookies-pop-up.read-more": "En savoir plus",

  "security": "Sécurité",
  "security.title": "Votre sécurité est notre priorité",
  "security.description": "Nous nous engageons à fournir des solutions de cybersécurité robustes et fiables. Avec nous, sécurisez votre environnement numérique et assurez un fonctionnement fluide et sécurisé de vos opérations commerciales.",
  "security.first-text": "À une époque où les cybermenaces évoluent plus rapidement que jamais, nous nous positionnons en tant que partenaire de confiance, offrant une forteresse de protection autour de vos précieux actifs numériques.",
  "security.features.first.title": "Prévention des menaces et réponse",
  "security.features.first.body": "Notre approche proactive détecte et atténue les menaces avant qu'elles ne puissent causer de dommages, et nos mécanismes de réponse rapides assurent une perturbation minimale en cas de violation improprement dite.",
  "security.features.second.title": "Cryptage des données et confidentialité",
  "security.features.second.body": "Nous utilisons des normes de cryptage avancées pour protéger vos données sensibles au repos et en transit, garantissant votre confidentialité et assurant la conformité avec les exigences réglementaires.",
  "security.features.third.title": "Gestion de l'identité et de l'accès",
  "security.features.third.body": "Nous nous assurons que seules les personnes autorisées ont accès à vos ressources numériques, aidant à prévenir l'accès non autorisé et les potentielles violations de la sécurité.",
  "security.subtitle": "Garder votre frontière numérique",
  "security.second-text": "En nous confiant vos besoins en cybersécurité, vous choisissez la tranquillité d'esprit. Notre équipe d'experts est à la pointe de la cybersécurité, mettant constamment à jour nos outils et tactiques pour contrer les menaces émergentes.",
  "security.third-text": "Rejoignez-nous et renforcez votre posture de cybersécurité, assurant que votre entreprise prospère dans un environnement numérique sûr, sécurisé et résilient.",

  "smart-home": "Maison Intelligente",
  "smart-home.title": "Transformez votre maison en un havre connecté et innovant avec des normes de sécurité",
  "smart-home.text-first": "Utilisez les derniers systèmes d'automatisation sécurisés et améliorés pour créer une connectivité sûre et conviviale entre :",
  "smart-home.text-second": "Système de lumières - Audio/Vidéo - CCTV - Alarme - Biométrie - Cinéma",
  "smart-home.text-third": "Et tout système sur mesure* pour personnaliser l'infrastructure IT de votre maison conforme à la cybersécurité",
  "smart-home.text-fourth": "*Certifié Lutron – Crestron Maintenance : 24/7 par contrat",
  "smart-home.learn-more": "Contacter les ventes",

  "blockchain.title": "La blockchain est l'avenir",
  "blockchain.first": "La Blockchain, les Cryptomonnaies et les NFTs n'appartiennent plus au futur : ces nouvelles technologies sont sur le point de façonner profondément l'économie dans les années à venir, candidatant le Métaverse comme future représentation probable de l'Internet.",
  "blockchain.second": "Nous saisissons l'opportunité de devenir un écosystème web 3.0 de référence à l'échelle internationale, en améliorant notre avantage concurrentiel tout en catalysant et en connectant les opportunités à notre écosystème réel. MVE soutient le développement et l'agrégation autour de la technologie Blockchain : nous avons l'intention de diffuser la Blockchain à un public plus large et de favoriser l'utilisation de crypto et de technologies décentralisées afin de générer une valeur Économique, Financière et Culturelle pour Monaco et votre pays, en favorisant une transition de changement de paradigme pour tous.",
  "blockchain.third": "Tim Berners-Lee, inventeur du World Wide Web (WWW), professeur d'informatique à l'Université d'Oxford et au MIT a dit :",
  "blockchain.fourth": "“Le Web tel que je l'avais envisagé, nous ne l'avons pas encore vu. L'avenir est encore beaucoup plus grand que le passé”",
  "blockchain.contact": "Contacter les ventes",

  "cloud": "Intégrateur Cloud",
  "cloud.title": "Découvrez le potentiel de votre entreprise avec nos solutions cloud",
  "cloud.description": "En tant qu'intégrateurs de cloud, nous nous engageons à fournir des solutions cloud de pointe pour aider à rationaliser vos opérations, améliorer la productivité et garantir le plus haut niveau de sécurité.",
  "cloud.blockquote": "Dans le monde des affaires, le cloud n'est pas seulement un autre outil, c'est une rampe de lancement pour l'innovation.",
  "cloud.first-text": "Exploitez le pouvoir transformateur du cloud pour maximiser le potentiel de votre organisation. Que ce soit pour le stockage de données, la distribution de logiciels ou la puissance de calcul, nos services cloud ont ce qu'il vous faut.",
  "cloud.features.first.title": "Scalabilité infinie",
  "cloud.features.first.body": "Comme un ciel en constante expansion, nos services cloud offrent une scalabilité illimitée pour accompagner la croissance de votre entreprise. Augmentez ou réduisez vos ressources en fonction de vos besoins actuels.",
  "cloud.features.second.title": "Sécurité inégalée",
  "cloud.features.second.body": "Nous garantissons que vos données sont toujours sécurisées. Nos techniques de chiffrement avancées et nos protocoles de sécurité rigoureux protègent vos informations sensibles contre les menaces potentielles.",
  "cloud.features.third.title": "Infrastructure de serveurs fiable",
  "cloud.features.third.body": "Profitez de notre technologie de serveurs de pointe qui garantit des performances élevées, la fiabilité et la disponibilité, assurant que vos opérations ne sont jamais interrompues.",
  "cloud.subtitle": "Exploitez le pouvoir de l'intégration",
  "cloud.second-text": "Vivez l'impact transformateur de l'intégration de vos applications et systèmes dans le cloud. Ce n'est pas juste un stockage, c'est une nouvelle façon de comprendre et de rationaliser vos opérations commerciales. Commençons ensemble le voyage vers un futur plus intelligent, intégré au cloud.",

  "captcha": "Captcha",
  "captcha.loading": "Chargement...",

  "mail.sendSuccess": "Votre message a été envoyé avec succès",
  "mail.sendError": "Une erreur s'est produite",
  "mail.send": "Message envoyé",
  "mail.notSend": "Erreur",

  "fonds-bleu.quote": "Le Fond Bleu Extended Monaco est un véritable tremplin pour la transition numérique des entreprises",
  "fonds-bleu.role": "CEO à MVE",
  "fonds-bleu": "Fond Bleu Monaco",
  "fonds-bleu.title": "Monaco lance son fonds d’aide à la transition digitale",
  "fonds-bleu.text.first": "Le Fond Bleu est un fonds de soutien mis en place par le Gouvernement dans le cadre du programme Extended Monaco. Il vise à favoriser la relance économique via le numérique en répondant à trois objectifs :",
  "fonds-bleu.text.second": "Développer la maturité numérique des entreprises monégasques ;",
  "fonds-bleu.text.third": "Promouvoir un écosystème favorable à l’économie numérique ;",
  "fonds-bleu.text.fourth": "Soutenir la filière monégasque des services numériques aux entreprises.",
  "fonds-bleu.text.fifth": "Sur un principe de cofinancement, le Fond Bleu accompagne les projets de transition numérique qui s’inscrivent dans ces objectifs.",
  "fonds-bleu.learn-more": "En savoir plus sur le Fond Bleu",
  "fond-bleu.stats.label.first": "Plan de soutien",
  "fond-bleu.stats.label.second": "Fondée depuis",
  "fond-bleu.stats.label.third": "Prise en charge",

  "devops": "DevOps & Développement",
  "devops.title": "Optimisez votre présence numérique avec nos solutions intégrées",
  "devops.description": "Spécialisés en DevOps, nous fusionnons développement et opérations pour des solutions fluides, que ce soit pour le web, le mobile ou la surveillance de vos systèmes.",
  "devops.blockquote": "La synergie entre développement et opérations est notre mantra. Entrez dans le monde de la performance et de la fiabilité avec DevOps.",
  "devops.first-text": "Réalisez vos visions avec une approche DevOps. Entre création d'applications et optimisation des systèmes, nous couvrons tout le spectre.",
  "devops.features.first.title": "Solutions sur mesure",
  "devops.features.first.body": "Transformez vos idées en réalités performantes, adaptées à vos besoins et prêtes pour le futur.",
  "devops.features.second.title": "Surveillance proactive",
  "devops.features.second.body": "Restez informé et prêt à réagir grâce à nos outils de monitoring avancés.",
  "devops.features.third.title": "Cohésion et automatisation",
  "devops.features.third.body": "Grâce à une intégration DevOps poussée, nous assurons une transition fluide entre le développement et les opérations, maximisant ainsi la productivité et la stabilité.",
  "devops.subtitle": "La puissance de DevOps à votre service",
  "devops.second-text": "Expérimentez une harmonie entre création, déploiement et surveillance. Avec DevOps, embrassez une méthode agile, centrée sur la collaboration et l'efficacité.",

  "careers.contact.title": "Carrières",
  "careers.contact.subtitle": "Vous recherchez votre prochaine opportunité de carrière ?",
  "careers.contact.open": "Ouvert",
  "careers.contact.close": "Fermé",
  "careers.contact.first": "Dessinateur Autocad CFO CFA",
  "careers.contact.first.description": "",
  "careers.contact.second": "Auditeur pentest",
  "careers.contact.second.description": "Requis: Connaissance ISO 27001, Expérience PASSI serait un +",
  "careers.errorMessage.title": "Erreur",
  "careers.errorMessage.description": "Offre non disponible pour le moment."
}
